<template>
  <section class="day">
    <div class="container">
      <div class="columns">
        <div class="column">
          <h1 class="title is-2">{{ day }}</h1>
        </div>
      </div>
      <div class="columns">
        <div class="column is-9">
          <AgendaItem
            v-for="item in agendas"
            :key="item.id"
            :id="item.id"
            :title="item.title"
            :speaker="item.speaker"
            :cospeaker="item.co_speaker"
            :time="item.time"
          ></AgendaItem>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import AgendaItem from "../molecules/AgendaItem";
export default {
  name: "Day",
  components: { AgendaItem },
  props: ["day", "agendas"],
};
</script>

<style lang="scss">
.day {
  margin-top: 3rem;
  margin-bottom: 3rem;
}
</style>
