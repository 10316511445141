<template>
  <section class="gallery--grid">
    <div class="container">
      <div class="columns">
        <div class="column">
          <a :href="downloadLink" class="button is-primary" target="_blank"
            >Download all photos in a single zip file.</a
          >
        </div>
      </div>
      <div class="columns is-multiline">
        <div
          class="column is-2"
          v-for="(image, key) in smallImages"
          :key="key"
          @click="index = key"
        >
          <gallery-grid-item :thumbnail="image" :alt="key"></gallery-grid-item>
        </div>
      </div>
    </div>
    <vue-gallery
      :images="largeImages"
      :index="index"
      @close="index = null"
    ></vue-gallery>
  </section>
</template>

<script>
import GalleryGridItem from "../atoms/GalleryGridItem";
import VueGallery from "vue-gallery";

import imagesList from "../../data/gallery";
import galleryZip from "../../data/galleryZip";

export default {
  name: "GalleryGrid",
  components: { GalleryGridItem, VueGallery },
  data() {
    return {
      dataImageList: imagesList,
      index: null,
    };
  },
  computed: {
    largeImages() {
      return this.dataImageList.map(
        (image) => process.env.VUE_APP_IMAGES_URL_LARGE + image
      );
    },
    smallImages() {
      return this.dataImageList.map(
        (image) => process.env.VUE_APP_IMAGES_URL_SMALL + image
      );
    },
    downloadLink() {
      return galleryZip.url;
    },
  },
};
</script>

<style lang="scss">
.gallery--grid {
  & > .container > .columns {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}
#blueimp-gallery {
  a:after {
    content: none;
  }
}
</style>
