<template>
  <div>
    <Hero title="Registration" subtitle="Sewio Summit 2023"></Hero>
    <div class="section">
      <div class="container venue">
        <div class="columns is-multiline" v-if="submitted === false">
          <div class="column is-8">
            <div class="content" v-html="page.content"></div>
          </div>
          <div class="column is-5">
            <form action="">
              <h2 class="subtitle">Registration Data</h2>
              <b-field label="First name" horizontal
                       :type="!!errors.first('first_name') ? 'is-danger' : ''"
                       :message="errors.first('first_name')">
                <b-input
                    v-model="form.first_name" v-validate="'required'"
                    name="first_name" placeholder="John"></b-input>
              </b-field>
              <b-field label="Last name" horizontal
                       :type="!!errors.first('last_name') ? 'is-danger' : ''"
                       :message="errors.first('last_name')">
                <b-input v-model="form.last_name" v-validate="'required'" name="last_name" placeholder="Doe"></b-input>
              </b-field>
              <b-field label="Job title" horizontal
                       :type="!!errors.first('job_title') ? 'is-danger' : ''"
                       :message="errors.first('job_title')">
                <b-input v-model="form.job_title" v-validate="'required'" name="job_title" placeholder="CEO"></b-input>
              </b-field>
              <b-field label="Company" horizontal
                       :type="!!errors.first('company') ? 'is-danger' : ''"
                       :message="errors.first('company')">
                <b-input v-model="form.company" v-validate="'required'" name="company" placeholder="Example Inc."></b-input>
              </b-field>
              <b-field label="Business email" horizontal
                       :type="!!errors.first('email') ? 'is-danger' : ''"
                       :message="errors.first('email')">
                <b-input v-model="form.email" v-validate="'required|email'" name="email" placeholder="john.doe@example.com"></b-input>
              </b-field>
              <b-field label="Phone" horizontal
                       :type="!!errors.first('phone') ? 'is-danger' : ''"
                       :message="errors.first('phone')">
                <b-input v-model="form.phone" placeholder="+420 123 456 789 (optional)"></b-input>
              </b-field>

              <b-field label="Additional comments" horizontal
                       :type="!!errors.first('comments') ? 'is-danger' : ''"
                       :message="errors.first('comments')">
                <b-input v-model="form.message" type="textarea" placeholder="(optional)"></b-input>
              </b-field>

              <b-field label="Discount Code" horizontal
                       :type="!!errors.first('discount_code') ? 'is-danger' : ''"
                       :message="errors.first('discount_code')">
                <b-input v-model="form.discount_code" placeholder="(optional)"></b-input>
              </b-field>

              <h2 class="subtitle">Billing Data</h2>
              <b-field label="Company Name" horizontal
                       :type="!!errors.first('invoice_company') ? 'is-danger' : ''"
                       :message="errors.first('invoice_company')">
                <b-input v-model="form.invoice_company" v-validate="'required'" name="invoice_company" placeholder="Example Inc."></b-input>
              </b-field>
              <b-field label="Street and No." horizontal
                       :type="!!errors.first('street') ? 'is-danger' : ''"
                       :message="errors.first('street')">
                <b-input v-model="form.street" v-validate="'required'" name="street" placeholder="Street and No."></b-input>
              </b-field>
              <b-field label="City" horizontal
                       :type="!!errors.first('city') ? 'is-danger' : ''"
                       :message="errors.first('city')">
                <b-input v-model="form.city" v-validate="'required'" name="city" placeholder="Brno"></b-input>
              </b-field>
              <b-field label="Postcode" horizontal
                       :type="!!errors.first('postcode') ? 'is-danger' : ''"
                       :message="errors.first('postcode')">
                <b-input v-model="form.postcode" v-validate="'required'" name="postcode" placeholder="612 00"></b-input>
              </b-field>
              <b-field label="Country" horizontal
                       :type="!!errors.first('country') ? 'is-danger' : ''"
                       :message="errors.first('country')">
                <b-input v-model="form.country" v-validate="'required'" name="country" placeholder="Czechia"></b-input>
              </b-field>
              <b-field label="VAT Number" horizontal
                       :type="!!errors.first('vat_no') ? 'is-danger' : ''"
                       :message="errors.first('vat_no')">
                <b-input v-model="form.vat_no" name="vat_no" placeholder="(optional)"></b-input>
              </b-field>

              <hr>

              <b-field label=""
                       :type="!!errors.first('want_evening_prague') ? 'is-danger' : ''"
                       :message="errors.first('want_evening_prague')">
                <b-checkbox v-model="form.want_evening_prague" name="want_evening_prague">&nbsp;I’ll attend Tuesday's free Evening Prague Walking Tour.</b-checkbox>
              </b-field>

              <b-field label=""
                       :type="!!errors.first('want_prakab') ? 'is-danger' : ''"
                       :message="errors.first('want_prakab')">
                <b-checkbox v-model="form.want_prakab" name="want_prakab">&nbsp;I’ll attend Thursday’s free Visit to the Prakab Cabling Factory.</b-checkbox>
              </b-field>

              <p class="help">Please note: The maximum capacity for this tour has been reached. By registering now, you will be placed on a waiting list.</p>

              <hr>

              <b-field label=""
                       :type="!!errors.first('consent') ? 'is-danger' : ''"
                       :message="errors.first('consent')">
                <b-checkbox v-model="form.consent" v-validate="'required:true'" name="consent">&nbsp;I order the ticket for €349 (without VAT) and agree with being contacted by Sewio</b-checkbox>
              </b-field>

              <div class="buttons is-centered">
                <button @click="onSubmit($event)" class="button is-primary is-fullwidth" :class="{'is-loading': sending}">Register for Sewio Summit</button>
              </div>
            </form>
            <br>
            <br>
          </div>
        </div>
        <div class="columns" v-else>
          <div class="column is-7">
            Thank you for registering to Sewio Summit 2023.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Hero from "../common/Hero";
import axios from "axios";

export default {
  name: "Register",
  components: { Hero },
  data() {
    return {
      form: {
        first_name: null,
        last_name: null,
        job_title: null,
        company: null,
        email: null,
        phone: null,
        message: null,
        consent: false,
        discount_code: null,
        invoice_company: null,
        street: null,
        city: null,
        postcode: null,
        country: null,
        vat_no: null,
        want_evening_prague: null,
        want_prakab: null,
      },
      result: null,
      sending: false,
      submitted: false,
    };
  },
  computed: {
    page() {
      return this.$root.$data.register;
    },
  },
  methods: {
    onSubmit(event) {
      if (event) event.preventDefault();

      this.$validator.validate().then((result) => {
        this.result = result;

        if (result) {
          this.sending = true;
          axios
            .post(process.env.VUE_APP_API_URL + "register", this.form)
            .then(() => {
              this.sending = false;
              this.success();
              this.clearForm();
            })
            .catch(() => {
              this.sending = false;
              this.error();
            });
        } else {
          this.sending = false;
          this.error();
        }
      });
    },
    success() {
      this.$buefy.toast.open({
        message: "Registration form was send successfully. Thank you!",
        type: "is-success",
      });

      if (typeof gtag !== "undefined") {
        // eslint-disable-next-line no-undef
        gtag("event", "sign_up", {
          event_category: "RegistrationForm",
        });
      }
    },
    error() {
      this.$buefy.toast.open({
        message: "Please fill all required fields as described.",
        type: "is-danger",
      });
    },
    clearForm() {
      this.form = {
        first_name: null,
        last_name: null,
        job_title: null,
        company: null,
        email: null,
        phone: null,
        message: null,
        consent: false,
        discount_code: null,
        invoice_company: null,
        street: null,
        city: null,
        postcode: null,
        country: null,
        vat_no: null,
        want_evening_prague: null,
        want_prakab: null,
      };

      this.$nextTick().then(() => this.$validator.errors.clear());
    },
  },
};
</script>

<style lang="scss">
@import "../../scss/variables";

.button {
  &.is-primary {
    background-color: $primary;
    &:hover {
      background-color: $primary-light;
    }
  }
}
</style>

<style lang="scss" scoped>
.field ::v-deep .field-label {
  flex-grow: 2;
}
</style>
