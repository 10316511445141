import Vue from "vue";
import VueRouter from "vue-router";
import App from "./App.vue";
import Buefy from "buefy";
import VeeValidate from "vee-validate";
import { Validator } from "vee-validate";
import dictionary from "./dictionary";
import Meta from "vue-meta";
import VueScrollTo from "vue-scrollto";

// Route Components
import Homepage from "./components/pages/Homepage";
import Venue from "./components/pages/Venue";
import Agenda from "./components/pages/Agenda";
import Sessions from "./components/pages/Sessions";
import Gallery from "./components/pages/Gallery";
import Tour from "./components/pages/Tour";
import Register from "./components/pages/Register.vue";
import Social from "@/components/pages/Social.vue";

// FontAwesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(fab, faEnvelope);

Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false;

Vue.use(Buefy);

Vue.use(VueRouter);

Vue.use(VeeValidate);

Vue.use(Meta);

Vue.use(VueScrollTo);

// Register dictionary for validation
Validator.localize(dictionary);

const routes = [
  { path: "/", component: Homepage, name: "homepage" },
  { path: "/agenda", component: Agenda, name: "agenda" },
  { path: "/sessions", component: Sessions, name: "sessions" },
  { path: "/tour", component: Tour, name: "tour" },
  { path: "/venue", component: Venue, name: "venue" },
  { path: "/social", component: Social, name: "social" },
  //{ path: "/register", component: Register, name: "register" },
  { path: "/gallery", component: Gallery, name: "gallery" },
];

new Vue({
  router: new VueRouter({
    mode: "history",
    // eslint-disable-next-line
    scrollBehavior: function (to, from, savedPosition) {
      if (to.hash) {
        return {selector: to.hash};
      } else {
        return {x: 0, y: 0};
      }
    },
    routes: routes,
  }),
  data: {
    config: {
      assets_domain: 'https://backend.summit2023.sewio.net',
    },
    days: false,
    venue: false,
    tour: false,
    register: false,
    speakers: false,
    propositions: false,
    social: false,
  },
  methods: {
    imgSourceAddDomain(content) {
      if (typeof content !== 'string' && !(content instanceof String)) {
        return content;
      }

      return content.replace(/(<img[^>]*src=")(?!https?:\/\/)([^"]+)/g, `$1${this.$root.$data.config.assets_domain}$2`);
    }
  },
  render: (h) => h(App),
}).$mount("#app");
