<template>
  <div>
    <Hero
      title="Digitization in Manufacturing:<br>The Power of RTLS in Overcoming Industry Challenges"
      subtitle="Mama Shelter Hotel, Prague<br>September 20-21, 2023 (Wed - Thu)">
      <template v-slot:content>
        <p>The third Sewio Summit will showcase how RTLS technology and our partner eco system are helping manufacturing companies overcome the most pressing challenges of our time - <b>labor shortage, supply chain disruptions, and increased inbound costs</b>.</p>
        <p>Get inspired by real-world case studies and metrics-backed digitization success stories from industry innovation leaders who have leveraged RTLS to streamline their operations, increase efficiency, and drive profitability. Join us at <b>#SewioSummit</b>, where innovations accelerate!</p>
      </template>
    </Hero>
    <div class="section homepage--line-up">
      <div class="container">
        <line-up></line-up>
      </div>
    </div>
    <div class="section homepage--value-propositions">
      <div class="container">
        <div class="columns">
          <div class="column">
            <br />
            <p class="title is-2">Need to Convince Your Boss?</p>
            <p class="subtitle is-4">
              Here are some talking points to make your case.
            </p>
            <br />
          </div>
        </div>
        <value-propositions></value-propositions>
      </div>
    </div>
    <div class="venue-image-aspect-ratio">
      <img
        src="../../assets/venue.jpg"
        alt="Venue"
        class="venue-image"
      />
    </div>
  </div>
</template>

<script>
import ValuePropositions from "../organisms/ValuePropositions";
import Hero from "../common/Hero";
import LineUp from "../molecules/LineUp";

export default {
  name: "Homepage",
  components: { LineUp, Hero, ValuePropositions },
};
</script>

<style lang="scss">
@import "../../scss/variables";

.venue-image-aspect-ratio {
  display: block;
  width: 100%;
  padding-bottom: 35%;
  position: relative;
}
.venue-image {
  position: absolute;

  left: 0;
  top: 0;
  bottom: 0;
  right: 0;

  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center 35%;
}

.homepage {
  &--value-propositions {
    background-color: $light-background;
  }
}
</style>
