<template>
  <a
    :href="link"
    target="_blank"
    class="button is-not-rounded"
    :data-type="type"
  >
    <font-awesome-icon v-if="type === 'twitter'" :icon="['fab', 'twitter']" />
    <font-awesome-icon
      v-else-if="type === 'linkedin'"
      :icon="['fab', 'linkedin']"
    />
  </a>
</template>

<script>
export default {
  name: "SocialButton",
  props: ["link", "type"],
};
</script>

<style lang="scss">
@import "../../scss/variables";

$twitter-color: #55acee;
$linkedin-color: #007bb6;

.button.is-not-rounded {
  border-radius: 0;
  color: $white;
  transition: box-shadow 0.1s ease-in-out;
  &:hover {
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
    color: $white;
  }
}

.button[data-type="twitter"] {
  background-color: $twitter-color;
  border-color: $twitter-color;

  &:hover {
    border-color: $twitter-color;
  }
}

.button[data-type="linkedin"] {
  background-color: $linkedin-color;
  border-color: $linkedin-color;

  &:hover {
    border-color: $linkedin-color;
  }
}
</style>
