export default {
  en: {
    attributes: {
      first_name: "First name",
      last_name: "Last name",
      job_title: "Job title",
      company: "Company",
      email: "Business email",
      phone: "Phone",
      message: "Additional comments",
      consent: "Consent",
      invoice_company: "Invoice Company",
      street: "Street",
      city: "City",
      postcode: "Postcode",
      country: "Country",
    },
    custom: {
      consent: {
        required: "Your consent is required.",
      },
    },
  },
};
