<template>
  <img :src="thumbnail" :alt="alt" />
</template>

<script>
export default {
  name: "GalleryGridItem",
  props: ["thumbnail", "alt"],
};
</script>

<style lang="scss" scoped>
img {
  width: 100%;
  height: 100px;
  object-fit: cover;
}
</style>
