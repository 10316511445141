<template>
  <div class="columns">
    <div class="column is-narrow session-item--image">
      <img :src="speaker.image" :alt="speaker.name" />
    </div>
    <div class="column session-item--info">
      <p>{{ speaker.fullname }}</p>
      <p>{{ speaker.position }} at {{ speaker.company }}</p>
      <SocialButton
        v-if="speaker.linked_in"
        type="linkedin"
        :link="speaker.linked_in"
      ></SocialButton>
      <SocialButton
        v-if="speaker.twitter"
        type="twitter"
        :link="speaker.twitter"
      ></SocialButton>
    </div>
  </div>
</template>

<script>
import SocialButton from "../atoms/SocialButton";
export default {
  name: "SessionSpeaker",
  components: { SocialButton },
  props: ["speaker"],
};
</script>
